<template>
  <div
    class="w-full h-auto flex flex-col justify-center items-center space-y-2 font-dinpro text-2xl text-primary-1000 my-6 tracking-tight"
  >
    <div class="pb-2" @click="scrollToTop">
      <Icon name="tabler:chevron-up" class="w-6 h-6 text-primary-1000" />
    </div>
    <NuxtLink :to="generateLink('/shop/')">{{ t('nav.shop') }}</NuxtLink>
    <NuxtLink :to="generateLink('/stories')">{{ t('footer.stories') }}</NuxtLink>
    <NuxtLink :to="generateLink('/contact')">{{ t('footer.contact') }}</NuxtLink>
    <NuxtLink :to="generateLink('/return-policy')">{{ t('footer.returns') }}</NuxtLink>
    <NuxtLink :to="generateLink('/terms-and-conditions')">{{ t('footer.terms') }}</NuxtLink>
    <NuxtLink :to="generateLink('/privacy-policy')">{{ t('footer.privacy') }}</NuxtLink>
    <div class="pt-6 flex justify-center items-center gap-x-8 h-auto">
      <a :href="settings?.instagram" target="_blank">
        <Icon name="tabler:brand-instagram" class="w-8 h-8 text-primary-1000" />
      </a>
      <a :href="settings?.facebook" target="_blank">
        <Icon name="tabler:brand-facebook-filled" class="w-8 h-8 text-primary-1000" />
      </a>
      <a :href="settings?.youtube" target="_blank">
        <Icon name="tabler:brand-youtube-filled" class="w-8 h-8 text-primary-1000" />
      </a>
    </div>
  </div>
  <div class="w-full h-auto flex flex-col justify-center items-center space-y-2 text-lg tracking-tight my-4">
    <div class="flex justify-center items-center gap-x-8 text-[#8E9093]">
      <NuxtLink :to="generateLink('/why-biogena')">{{ t('nav.about') }}</NuxtLink>
      <NuxtLink :to="generateLink('/faq')">{{ t('footer.faq') }}</NuxtLink>
      <NuxtLink :to="generateLink('/warnings')">{{ t('footer.warnings') }}</NuxtLink>
    </div>
    <div class="w-1/3 py-2">
      <img src="~/assets/images/biogena-gray-logo.svg" />
    </div>
    <div class="flex flex-wrap gap-x-2 pb-2">
      <img src="~/assets/images/visa_logo.png" class="w-10 object-contain" />
      <img src="~/assets/images/mastercard_logo.png" class="w-10 object-contain" />
      <img src="~/assets/images/tabby_logo.svg" class="w-16" />
      <img src="~/assets/images/apple_pay_logo.svg" class="w-10 object-contain" />
      <img src="~/assets/images/google_pay_logo.svg" class="w-10 object-contain" />
    </div>
    <div class="w-2/3 text-center text-[#8E9093]">©{{ new Date().getFullYear() }} {{ t('footer.distribution') }}</div>
  </div>
  <div class="text-[#CCCECF] px-10 text-center text-sm mb-44 lg:mb-0">
    <p>
      {{ t('footer.disclaimer') }}
    </p>
    <br />
    <p>
      {{ t('footer.disclaimer2') }}
    </p>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const settingsStore = useSettingsStore();
const { settings } = storeToRefs(settingsStore);
</script>
